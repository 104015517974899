import Papa from 'papaparse'
import React from 'react'
import Dropzone from 'react-dropzone'

import { AnchorButton, Button, Footnote, Panel } from '../../styles/_app.style'
import { DropzoneContainer, InnerContainer } from '../../styles/exercise/markManagementArea.style'
import { RawMarks } from '../../types/global'
import Tooltip from '../tooltip/Tooltip'

interface MarksVisibilityOptions {
  published: boolean
  userCanUpdate: boolean
  update: (visibility: boolean) => void
}

export const MarkManagementArea = ({
  marksToSave,
  onMarkFileUpload,
  onMarksSave,
  marksVisibility,
  csvTemplate,
  marksNotForPublication,
}: {
  marksToSave: RawMarks
  onMarkFileUpload: (file: File) => void
  onMarksSave: (marks: RawMarks) => void
  marksVisibility: MarksVisibilityOptions
  csvTemplate: { data: any; name: string }
  marksNotForPublication: boolean
}) => {
  function generateCSVTemplateBlob() {
    const csv = Papa.unparse(csvTemplate.data)
    return new Blob([csv], { type: 'text/csv;charset=utf-8;' })
  }

  const dropzoneEnabled = marksVisibility.userCanUpdate
  return (
    <Panel>
      <Dropzone
        accept={{ 'text/csv': ['.csv'] }}
        multiple={false}
        disabled={!dropzoneEnabled}
        onDrop={([file]) => onMarkFileUpload(file)}
      >
        {({ getRootProps, getInputProps }) => (
          <DropzoneContainer
            {...getRootProps({ className: 'dropzone' })}
            disabled={!dropzoneEnabled}
          >
            <input {...getInputProps()} disabled={!dropzoneEnabled} />
            <p>
              {dropzoneEnabled
                ? 'Drag or click to upload your marking CSV'
                : 'Marks changes not allowed at this time'}
            </p>
          </DropzoneContainer>
        )}
      </Dropzone>
      <InnerContainer>
        <Footnote muted center style={{ maxWidth: '24.13rem' }}>
          Marks are auto-capped at the exercise maximum mark and negative values are replaced with
          0. CSV lines with missing marks are skipped.
        </Footnote>
        <AnchorButton
          href={URL.createObjectURL(generateCSVTemplateBlob())}
          download={csvTemplate.name}
          title="Download marks (CSV)"
        >
          Download marks (CSV)
        </AnchorButton>
      </InnerContainer>
      <InnerContainer>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            gap: '0.5rem',
          }}
        >
          <Tooltip label="Save marks">
            <Button
              block
              disabled={!Object.keys(marksToSave).length}
              onClick={() => onMarksSave(marksToSave)}
              title="Save marks"
              warning={!!Object.keys(marksToSave).length}
            >
              Save Marks
            </Button>
          </Tooltip>
          {marksVisibility.userCanUpdate && (
            <Tooltip
              label={`Finalise marks ${
                marksNotForPublication ? '' : 'and make them visible to students'
              }`}
            >
              <Button block onClick={() => marksVisibility.update(!marksVisibility.published)}>
                {marksNotForPublication
                  ? marksVisibility.published
                    ? 'Unfinalise'
                    : 'Finalise'
                  : marksVisibility.published
                  ? 'Unpublish'
                  : 'Publish'}
              </Button>
            </Tooltip>
          )}
        </div>
      </InnerContainer>
      <Footnote muted center>
        {marksNotForPublication
          ? "Grades not for publication: your marks won't be visible to students."
          : 'Marks will be visible to students when published.'}
      </Footnote>
    </Panel>
  )
}

export const CW_MARKER_ROLES = [
  'CSL',
  'Exam Marker',
  'Lecturer',
  'Lecturer (Occ)',
  'Tutorial Helper',
  'Marker',
  'Tutor',
  'Lab Demonstrator',
]

export const LECTURER_ROLES = ['Lecturer', 'Lecturer (Occ)']

export const ALL_MARKER_ROLES = [...CW_MARKER_ROLES, 'UTA Tutor']

import {
  ExpandedState,
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, { useState } from 'react'
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'

import { Table, Td, Th, ThContainer } from '../../styles/table.style'
import { PhdStudentDetailMilestones } from '../../types/phds'
import { getPhDAcademicYearDates } from '../../utils'

export const PhdStudentsTable = ({
  phdStudents,
  mileStoneCode,
  shortYear,
}: {
  phdStudents: PhdStudentDetailMilestones[]
  mileStoneCode?: string
  shortYear?: string
}) => {
  const columnHelper = createColumnHelper<PhdStudentDetailMilestones>()

  let columns = [
    columnHelper.accessor('login', {
      cell: (info) => (
        <Link
          style={{ textDecoration: 'underline' }}
          to={`/${shortYear}/phd/${info.getValue()}`}
          title={`Details`}
        >
          {info.getValue()}
        </Link>
      ),
      header: () => 'Login',
      footer: (info) => info.column.id,
    }),

    columnHelper.accessor('cid', {
      cell: (info) => info.getValue(),
      header: () => 'CID',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('firstName', {
      cell: (info) => info.getValue(),
      header: () => 'First Name',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.lastName, {
      id: 'lastName',
      cell: (info) => info.getValue(),
      header: () => 'Last Name',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.supervisors, {
      id: 'supervisorName',
      cell: (info) => (
        <div>
          {info
            .getValue()
            .map((s) => `${s.firstName} ${s.lastName}`)
            .join(' ')}
        </div>
      ),
      header: () => 'Supervisor(s)',
      footer: (info) => info.column.id,
    }),

    columnHelper.accessor((row) => row.startDate, {
      id: 'startDate',
      cell: (info) => info.getValue(),
      header: () => 'Start Date',
      footer: (info) => info.column.id,
    }),
  ]

  if (mileStoneCode && shortYear) {
    const milestoneColumns = [
      columnHelper.accessor(
        (row) =>
          row.milestones.find((item) => item.code.toUpperCase() === mileStoneCode.toUpperCase())
            ?.dateRequired,
        {
          id: 'date_required',
          cell: (info) => info.getValue(),
          header: () => 'Required Date',
          footer: (info) => info.column.id,
        }
      ),
      columnHelper.accessor(
        (row) =>
          row.milestones.find((item) => item.code.toUpperCase() === mileStoneCode.toUpperCase())
            ?.dateSubmitted,
        {
          id: 'date_submitted',
          cell: (info) => info.getValue(),
          header: () => 'Submitted Date',
          footer: (info) => info.column.id,
        }
      ),
    ]

    columns = columns.concat(milestoneColumns)

    const { academicStartDate, academicEndDate } = getPhDAcademicYearDates(shortYear)

    phdStudents = phdStudents.filter((phd) => {
      const recordDate = new Date(phd.startDate)
      return recordDate >= academicStartDate && recordDate <= academicEndDate
    })
  }

  const [expanded, setExpanded] = useState<ExpandedState>({})

  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data: phdStudents,
    columns: columns,
    state: {
      expanded,
      sorting,
    },
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <div style={{ overflowX: 'scroll' }}>
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, headerIndex) => (
                <Th key={header.id} expander={headerIndex === 0}>
                  {!header.isPlaceholder && (
                    <ThContainer
                      className={header.column.getCanSort() ? 'cursor-pointer select-none' : ''}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <CaretUpFill />,
                        desc: <CaretDownFill />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </ThContainer>
                  )}
                </Th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

import { PhdStudentsTable } from '../components/phd/PhdStudentsTable'
import { usePhdStudents } from '../hooks/phd.service'
import { Banner, Container } from '../styles/_app.style'
import { formatShortYear } from '../utils'

const PhdDashboard = () => {
  const { phdStudents, phdStudentsLoaded } = usePhdStudents()
  const { year } = useParams()

  return (
    <>
      <Container>
        <Helmet>
          <title>PHD Tracking for {formatShortYear(year)}</title>
        </Helmet>
        <h1>PhD Tracking Dashboard for {formatShortYear(year)}</h1>
        {!phdStudentsLoaded && <Banner>Loading PhD students data...</Banner>}
        {phdStudentsLoaded && phdStudents.length === 0 && (
          <Banner>No PhD students data available.</Banner>
        )}
      </Container>
      {phdStudentsLoaded && phdStudents.length > 0 && (
        <Container section expandX>
          <PhdStudentsTable phdStudents={phdStudents} shortYear={year} />
        </Container>
      )}
    </>
  )
}

export default PhdDashboard

import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

import { PhdStudentsTable } from '../components/phd/PhdStudentsTable'
import { usePhdStudents } from '../hooks/phd.service'
import { Banner, Container } from '../styles/_app.style'
import { formatShortYear, getPhDAcademicYearDates } from '../utils'

const PhdDashboardByMileStone = () => {
  let { phdStudents, phdStudentsLoaded } = usePhdStudents()

  const { year, milestoneCode } = useParams()

  const { academicStartDate, academicEndDate } = getPhDAcademicYearDates(year!)

  phdStudents = phdStudents.filter((phd) => {
    const recordDate = new Date(phd.startDate)
    return recordDate >= academicStartDate && recordDate <= academicEndDate
  })

  return (
    <>
      <Container>
        <Helmet>
          <title>
            PHD Tracking for {formatShortYear(year)} and milestone {milestoneCode}
          </title>
        </Helmet>
        <h1>
          PhD Tracking Dashboard for {formatShortYear(year)} milestone:{' '}
          {milestoneCode?.toUpperCase()}
        </h1>
        {!phdStudentsLoaded && <Banner>Loading PhD students data...</Banner>}
        {phdStudentsLoaded && phdStudents.length === 0 && (
          <Banner>No PhD students data available.</Banner>
        )}
      </Container>
      {phdStudentsLoaded && phdStudents.length > 0 && (
        <Container section expandX>
          <PhdStudentsTable
            phdStudents={phdStudents}
            mileStoneCode={milestoneCode}
            shortYear={year}
          />
        </Container>
      )}
    </>
  )
}

export default PhdDashboardByMileStone

import { TooltipProvider } from '@radix-ui/react-tooltip'
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Navigate, Route, Routes } from 'react-router-dom'

import ExternalResource from '../components/ExternalResource'
import { YearRoute } from '../components/YearRoute'
import { AuthProtectedRoute } from '../components/guards/AuthProtectedRoute'
import { RoleProtectedRoute } from '../components/guards/RoleProtectedRoute'
import { StaffAndMatchingStudentOnly } from '../components/guards/StaffAndMatchingStudentOnly'
import { PhDAdminOnlyRoute, PhDStudentOnlyRoute } from '../components/guards/StaffAndPhDOnlyRoute'
import { StaffOnlyRoute } from '../components/guards/StaffOnlyRoute'
import { baseURL } from '../constants/endpoints'
import { SENIOR_TUTOR } from '../constants/global'
import { AxiosInstanceProvider } from '../contextManagers/axios.context'
import { GameProvider, globalGameEnabled } from '../contextManagers/game.context'
import { ThemeProvider } from '../contextManagers/theme.context'
import { ToastProvider } from '../contextManagers/toast.context'
import { UserProvider } from '../contextManagers/user.context'
import { Area, Viewport as ScrollViewport, Scrollbar, Thumb } from '../styles/_app.style'
import { globalStyles } from '../styles/stitches.config'
import { cateModeGlobalCSS } from '../styles/theming/cateMode'
import Analytics from './Analytics'
import AttendanceTracking from './AttendanceTracking'
import ColourTheme from './ColourTheme'
import Credits from './Credits'
import Exams from './Exams'
import ExerciseCreate from './ExerciseCreate'
import Exercises from './Exercises'
import InvalidRequest404 from './InvalidRequest404'
import Login from './Login'
import Marks from './MarkingOverview'
import Materials from './Materials'
import Module from './Module'
import Modules from './Modules'
import PastoralCare from './PastoralCare'
import PhdAdmin from './PhdAdmin'
import PhdDashboard from './PhdDashboard'
import PhdDashboardByMileStone from './PhdDashboardByMilestone'
import { PhdStudent } from './PhdStudent'
import { PhdStudentPersonal } from './PhdStudentPersonal'
import PublicMaterials from './PublicMaterials'
import StudentRecord from './StudentRecord'
import Timeline from './Timeline'
import Emarking from './emarkingDistribution/EmarkingDistribution'
import Exercise from './exercise/Exercise'
import ExerciseStaff from './exercise/ExerciseStaff'
import ExerciseStudent from './exercise/ExerciseStudent'
import ExerciseSubRouter from './exercise/ExerciseSubRouter'
import ExerciseUta from './exercise/ExerciseUta'
import CohortMarkingDashboard from './marksConsolidation/CohortMarkingDashboard'
import ModuleSelection from './moduleSelection/ModuleSelection'
import ExternalModuleChoicesReview from './moduleSelectionAdmin/ExternalModuleChoicesReview'
import ModuleSelectionConfiguration from './moduleSelectionAdmin/ModuleSelectionConfiguration'
import DeadlineExtensionsDashboard from './seniorTutor/DeadlineExtensionsDashboard'
import LateSubmissionsDashboard from './seniorTutor/LateSubmissionsDashboard'
import LatestSubmissionsDashboard from './seniorTutor/LatestSubmissionsDashboard'
import MissingSubmissionsDashboard from './seniorTutor/MissingSubmissionsDashboard'
import PersonalTutoringDashboard from './seniorTutor/PersonalTutoringDashboard'
import SeniorTutorHome from './seniorTutor/SeniorTutorHome'
import TutorialAttendanceDashboard from './seniorTutor/TutorialAttendanceDashboard'

function SeniorTutorRoutes() {
  return (
    <Routes>
      <Route path="/" element={<SeniorTutorHome />} />
      <Route path="personal-tutoring" element={<PersonalTutoringDashboard />} />
      <Route path="tutorial-sessions" element={<TutorialAttendanceDashboard />} />
      <Route path="deadline-extensions" element={<DeadlineExtensionsDashboard />} />
      <Route path="late-submissions" element={<LateSubmissionsDashboard />} />
      <Route path="latest-submissions" element={<LatestSubmissionsDashboard />} />
      <Route path="missing-submissions" element={<MissingSubmissionsDashboard />} />
    </Routes>
  )
}

function ModuleSelectionRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ModuleSelection />} />
      <Route path="configuration" element={<ModuleSelectionConfiguration />} />
      <Route path="external" element={<ExternalModuleChoicesReview />} />
    </Routes>
  )
}

function App() {
  globalStyles()
  cateModeGlobalCSS()
  return (
    <HelmetProvider>
      <ThemeProvider>
        <ToastProvider>
          <UserProvider>
            <TooltipProvider>
              <AxiosInstanceProvider config={{ baseURL }}>
                <GameProvider>
                  <Area>
                    <ScrollViewport>
                      <Routes>
                        <Route index element={<Login />} />
                        <Route path="public-resources" element={<PublicMaterials />} />
                        <Route path="credits" element={<Credits />} />
                        <Route element={<AuthProtectedRoute />}>
                          <Route path="external-resource" element={<ExternalResource />} />
                          <Route path=":year" element={<YearRoute />}>
                            <Route index element={<Timeline />} />
                            <Route
                              path="consolidation/cohorts"
                              element={<StaffOnlyRoute to={<CohortMarkingDashboard />} />}
                            />
                            <Route
                              path="senior-tutor/*"
                              element={
                                <RoleProtectedRoute
                                  roles={[SENIOR_TUTOR]}
                                  to={<SeniorTutorRoutes />}
                                />
                              }
                            />
                            <Route path="module-selection/*" element={<ModuleSelectionRoutes />} />
                            {globalGameEnabled && (
                              <Route path="analytics" element={<Analytics />} />
                            )}
                            <Route path="pastoral-care" element={<PastoralCare />} />
                            <Route
                              path="tutees"
                              element={<Navigate to="../pastoral-care" replace />}
                            />

                            <Route
                              path="students/:username"
                              element={<StaffAndMatchingStudentOnly to={<StudentRecord />} />}
                            />
                            <Route path="attendance" element={<AttendanceTracking />} />
                            <Route path="theme" element={<ColourTheme />} />
                            <Route path="timeline" element={<Timeline />} />
                            <Route path="exams" element={<Exams />} />
                            <Route path="modules">
                              <Route index element={<Modules />} />
                              <Route
                                path=":moduleCode/exercises/:exerciseNumber"
                                element={<Exercise />}
                              >
                                <Route index element={<ExerciseSubRouter />} />
                                <Route path="staff" element={<ExerciseStaff />} />
                                <Route path="uta" element={<ExerciseUta />} />
                                <Route path="student" element={<ExerciseStudent />} />
                                <Route path="marks" element={<Marks />} />
                                <Route path="emarking" element={<Emarking />} />
                              </Route>
                              <Route
                                path=":moduleCode/exercises/new"
                                element={<ExerciseCreate />}
                              />
                              <Route path=":moduleCode" element={<Module />}>
                                <Route index element={<Navigate to="materials" />} />
                                <Route path="materials" element={<Materials />} />
                                <Route path="exercises" element={<Exercises />} />
                              </Route>
                            </Route>
                            <Route path="phd">
                              <Route index element={<StaffOnlyRoute to={<PhdDashboard />} />} />

                              <Route
                                path="milestones/:milestoneCode"
                                element={<StaffOnlyRoute to={<PhdDashboardByMileStone />} />}
                              />
                              <Route
                                path=":login"
                                element={<StaffOnlyRoute to={<PhdStudent />} />}
                              />

                              <Route
                                path=":login/me"
                                element={<PhDStudentOnlyRoute to={<PhdStudentPersonal />} />}
                              />
                            </Route>

                            <Route path="phd-admin">
                              <Route index element={<PhDAdminOnlyRoute to={<PhdAdmin />} />} />
                            </Route>
                          </Route>
                        </Route>
                        <Route path="/404" element={<InvalidRequest404 />} />
                        <Route path="*" element={<InvalidRequest404 />} />
                      </Routes>
                    </ScrollViewport>
                    <Scrollbar orientation="vertical">
                      <Thumb />
                    </Scrollbar>
                  </Area>
                </GameProvider>
              </AxiosInstanceProvider>
            </TooltipProvider>
          </UserProvider>
        </ToastProvider>
      </ThemeProvider>
    </HelmetProvider>
  )
}

export default App
